"use client"
import {useRef, } from 'react';
import Image from 'next/image'

export type Props = {
    video: string;
    className: string
    width: number,
    height: number,
    thumbnailUrl?: string,
    showPlayIcon?: boolean,
    thumbnailQuality: 'default' | 'hqdefault' | 'mqdefault' | 'sddefault'
}

export default function YouTubeVideo({video, className, width, height, thumbnailUrl, thumbnailQuality, showPlayIcon=false}: Props) {
    const divRef = useRef<HTMLDivElement|null>(null);

    const onClick = () => {
        const iframe = document.createElement( "iframe" );
        iframe.setAttribute( "class", "w-full h-full" );
        iframe.setAttribute( "frameborder", "0" );
        iframe.setAttribute( "allowfullscreen", "1");
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.style.width = width.toString();
        iframe.style.height = height.toString();
        iframe.setAttribute( "src", `https://www.youtube.com/embed/${video}?rel=0&showinfo=1&autoplay=1` );
        if (divRef.current) {
            divRef.current.innerHTML = "";
            divRef.current.appendChild(iframe);
        }
    }


    return (
        <div ref={divRef} className={"cursor-pointer align-middle "+className}>
            <span onClick={onClick} className="ti-control-play position-absolute display-1 text-white" />
            <div className='relative group'>
                <Image onClick={onClick} loading="lazy" src={(thumbnailUrl?thumbnailUrl:`https://img.youtube.com/vi/${video}/${thumbnailQuality}.jpg`)} alt="YouTube Video Thumbnail" width={width} height={height} className="shadow" />
                {showPlayIcon &&
                <span
                    className="pointer-events-none absolute w-8 h-8 m-auto left-0 right-0 top-0 bottom-0 text-white opacity-60 items-center group-hover:opacity-100"
                    aria-hidden="true"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                    <path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
                    </svg>
                </span>
                }
            </div>
        </div>
    );
}